export var searchList = [
  { labe: '询价编号', code: 'inquirynumber', type: 'input' },
  { labe: '询价描述', code: 'inquiryDesc', type: 'input' },
  {
    labe: '询价类型',
    code: 'inquirytype',
    type: 'select',
    option: [
      { label: '单一供应商询价', value: '1' },
      { label: '多家供应商询价', value: '2' },
      { label: '普通竞价', value: '3' },
      { label: '反向竞价', value: '4' }
    ]
  },
  { labe: '公司名称', code: 'company', type: 'input', placeholder: '编码/名称' },
  {
    labe: '发布日期',
    code: 'releaseTime',
    type: 'daterange'
  },
  {
    labe: '截止日期',
    code: 'offerEndTime',
    type: 'daterange'
  }
]
export var searchData = {
  inquirynumber: '',
  inquiryDesc: '',
  inquirytype: '',
  company: '',
  releaseTime: '',
  offerEndTime: ''
}
export var tableField = [
  { label: '询价编号', code: 'inquiryCode', type: 'input', width: '60%' },
  { label: '询价描述', code: 'inquiryDesc', type: 'input', width: '90%' },
  {
    label: '询价类型',
    code: 'inquiryType',
    type: 'function',
    width: '60%',
    handle: (index, data) => {
      return data.inquiryType === 1 ? '单一供应商询价'
        : data.inquiryType === 2 ? '多家供应商询价' : data.inquiryType === 3 ? '普通竞价'
          : data.inquiryType === 4 ? '反向竞价' : ''
    }
  },
  { label: '公司名称', code: 'companyName', type: 'input', width: '70%' },
  { label: '发布日期', code: 'createDate', type: 'input', width: '70%' },
  { label: '发布人', code: '', type: 'input', width: '50%' },
  { label: '报价截止日期', code: 'inquiryEndTime', type: 'input', width: '70%' },
  {
    label: '操作',
    code: 'act',
    width: '40%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var menudata = [{
  label: '+起草合同',
  action: 'draftContract',
  id: 1
}
]

export var tableData = []

<template>
    <div>
      <!-- 询价公告 -->
      <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
      <global-table :tableField="tableField" :listId="listId" :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction"></global-table>
    </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { requestForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { searchList, searchData, tableField, tableData } from './js/InquiryNotice.js'
export default {
  components: { ListSearch, GlobalTable },
  name: 'InquiryNotice',
  props: ['listId'],
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      childData: { searchList: searchList, searchData: searchData },
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      id: []
    }
  },
  created () {
    this.inquiryList()
  },
  methods: {
    tableAction: function (index, data, clickBtn) {
      this.$router.push({ params: { viewName: 'InquiryDetails' }, query: { id: data.id, isQuote: data.isQuote } })
      this.$emit('OpenView', { openView: 'InquiryDetails' })
    },
    inquiryList () {
      var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      let fbKsTime = ''
      let fbJsTime = ''
      if (this.childData.searchData.releaseTime) {
        fbKsTime = dayjs(this.childData.searchData.releaseTime[0]).format('YYYY-MM-DD HH:mm:ss')
        fbJsTime = dayjs(this.childData.searchData.releaseTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      let bjKsTime = ''
      let bjJsTime = ''
      if (this.childData.searchData.offerEndTime) {
        bjKsTime = dayjs(this.childData.searchData.offerEndTime[0]).format('YYYY-MM-DD HH:mm:ss')
        bjJsTime = dayjs(this.childData.searchData.offerEndTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        code: this.childData.searchData.inquirynumber,
        itemName: this.childData.searchData.inquiryDesc,
        type: this.childData.searchData.inquirytype,
        company: this.childData.searchData.company,
        startTime: fbKsTime,
        endTime: fbJsTime,
        // endDate: this.childData.searchData.endDate,
        closeStartTime: bjKsTime,
        closeEndTime: bjJsTime
      }
      requestForm('/api/inquiry/getListBySupplier?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.paginationData.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.inquiryList()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.inquiryList()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.inquiryList()
    }
  }
}

</script>
<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
